.App {
  text-align: center;
  background-color: #f6f7f8;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.beary {
  color: #008cce;
}

.only {
  color: #06aeef;
}


.image-small {
  width: 500px;
  display: block;
  margin: 0 auto;
/* show if browser less than 540px width*/
  @media (max-width: 540px) {
    display: block;
  }
}

.image-large {
  display: none;
  margin: 0 auto;
/* show if browser less than 540px width*/
  @media (max-width: 540px) {
    display: none;
  }
}

.website {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 62px;
  margin-bottom: 10px;
}

.tagline {
  font-size: 22px;
  color: #008cce;
  text-align: center;
  margin: 0 8px 10px;
}

.taglineSubtitle {
  font-size: 22px;
  color: #008cce;
  text-align: center;
  margin: 10px;
  font-weight: 700;
}

.App-header {
  background-color: #f6f7f8;
  min-height: 100vh;
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  max-width: 600px;
  margin: 0 auto;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
